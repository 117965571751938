<template>
  <div>
    <StreamBarcodeReader
      @decode="onDecode"
      @loaded="onLoaded"
    ></StreamBarcodeReader>

    <!--<span>Результат: {{ result }}</span>-->
  </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  name: "QR",
  NewID: "",
  NewPIN: "",
  components: { StreamBarcodeReader },

  data() {
    return { rusult: { NewID: this.NewID, NewPIN: this.NewPIN } };
  },
  watch: {
    NewIDandPIN() {
      this.$emit("newData", { NewID: this.NewID, NewPIN: this.NewPIN });
    },
  },
  methods: {
    onDecode(result) {
      //console.log(result);
      this.result = result;

      let indxID = result.indexOf("id=");
      if (indxID !== -1) {
        //Найдено объявление ID, прочитаемс
        this.NewID = result.substr(indxID + 3, 5);
        //console.log("new id is: ", NewID);
      }

      let indxPIN = result.indexOf("pin=");
      if (indxPIN !== -1) {
        //Найдено объявление PIN, прочитаемс
        this.NewPIN = result.substr(indxPIN + 4, 3);
        //console.log("new pin is: ", NewPIN);
      }

      if (this.NewID !== "" && this.NewPIN !== "") {
        this.$emit("newData", { NewID: this.NewID, NewPIN: this.NewPIN });
        //console.log("Получены новые вводные: ID = ",this.NewID, ", PIN = ", this.NewPIN);
      }
    },
    onLoaded() {
      console.log("Loaded QR Scanner");
    },
  },
};
</script>
